



import React, { useContext } from 'react'
import ButtonHoverNone from '../buttonNotHover'
import { GatsbyImage } from 'gatsby-plugin-image'
import SplitSectionHalf from '../../Layouts/SplitSectionHalf'
import { arrowActive, arrowNActive } from "../../Functions/fadeTransition"
import ChangePage from "../../Context/ChangePage"
import Arrow from '../Arrow'
import { MobileContext } from '../../Context/mobile'

const NextPage = ({ nextPageName, nextPageImage, number, catSlug, location, nextPageSlug, className, display, ...props }) => {
    const { mobile } = useContext(MobileContext)

    return (
        <>
            <SplitSectionHalf
                className=" m-t-200  b-t-grey-1-br"
                innerClassName="top-grey"
                right={
                    <ChangePage to={nextPageSlug} className='flex flex-r flex-c-br p-l-0-br p-l-15 w-100p re h-100p flex-sb' onMouseEnter={(e) => { arrowActive(e.target) }} onMouseLeave={(e) => { arrowNActive(e.target) }}>
                        <div className="h-100p re n-e w-100p">
                            <div className="flex flex-c  w-100p h-100p flex-sb p-b-30-br-s ">
                                <div className="flex flex-c p-b-30-br">
                                    <h4 className="m-b-10">{nextPageImage ? "Next Product" : "Next Page"}</h4>
                                    <div className="flex flex-r al-i-c-br flex-sb al-i-s-br ">
                                        <div className="flex flex-r m-w-150 flex-sb flex-grow-1">
                                        <h3 className={"m-w-212-br " + (!nextPageImage && !mobile ? "m-b-160" : "")}>{nextPageName}</h3>
                                        {(((number || number  === 0) && display)) ?<h3>[{number}]</h3> : null}
                                        </div>
                                        {mobile ? (
                                            <ButtonHoverNone name="view" />
                                        )
                                            : null}
                                    </div>
                                </div>
                                <div>
                                    {!mobile ? <Arrow /> : null}
                                </div>
                            </div>
                        </div>
                        {nextPageImage?.gatsbyImageData ? <div className="m-h-270 h-100vh">
                            <GatsbyImage alt="" image={nextPageImage?.gatsbyImageData} style={{ height: '100%' }} objectPosition='100% 100%' objectFit="contain" />
                        </div> : null}
                    </ChangePage>
                }
                left={
                   ((!mobile && nextPageImage) ?
                        <ChangePage to={catSlug}>
                            <div className='flex flex-r'>
                                <div className='flex-center-v flex flex-c center-h'>
                                    <svg width="3" height="7" viewBox="0 0 3 7" fill="none" xmlns="http://www.w3.org/2000/svg" className="m-r-15">
                                        <path d="M1.5299e-07 3.5L3 6.53109L3 0.468911L1.5299e-07 3.5Z" fill="#485E4C" />
                                    </svg>
                                </div>
                                <h4 className="">All products</h4>

                            </div>
                        </ChangePage> : null)
                }
            />
        </>
    )
}

export default NextPage