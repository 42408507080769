import React, { useState } from "react"
import { graphql } from "gatsby"
import BodyWrapper from "../components/Layouts/BodyWrapper"
import Footer from "../components/MediatorComponents/Footer"
import NextPage from "../components/ResuableComponents/NextPage"
import Layout from "../components/layout"
import Seo from "../components/seo.js"
import LineGen from "../components/ResuableComponents/LineGen/LineGen"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"

const Row = ({ left, right }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  return (
    <div className={`p-c row-wrapper policies-row-wrapper ${isCollapsed ? "collapsed" : "open"}`} onClick={() => setIsCollapsed(previousState => !previousState)}>
      <LineGen numberOfLines={1} />
      <div className='flex flex-c p-t-15 pb-15 p-t-15-br-s flex-sb align-center'>
        <div className={`w-100  flex justify-between  align-center ml-a pr-10 ${isCollapsed ? "pb-0" : "pb-10"}`}>
          {left} <div className={`icon ${isCollapsed ? "closed" : "open"}`} />
        </div>
        {!isCollapsed && <div className={`row-content flex-grow-1 m-w-210-br ml-a mr-15 mb-15 w-100  policies-row-content`}>{right}</div>}
      </div>
    </div>
  )
}

const Policies = ({ data, location }) => {
  const policies = data.policies.policy
  return (
    <Layout location={location}>
      <Seo title='Collection' />
      <BodyWrapper className='p-t-15 c-15'>
        <div className='flex flex-r flex-sb p-b-300 p-t-40-br p-b-90-br'>
          <h2>Policies</h2>
        </div>
        {policies.map(policy => (
          <Row
            left={<p>{policy.policySectionTitle}</p>}
            right={
              <>
                {policy.policyDetails.map(({ description }) => (
                  <ReactMarkdown className='policies-row-paragraph'>{description}</ReactMarkdown>
                ))}
              </>
            }
          />
        ))}
        <NextPage nextPageName={"studio"} nextPageSlug={"/studio"} location={"/studio"} display={false} />
        <Footer location={location} />
      </BodyWrapper>
    </Layout>
  )
}

export default Policies

export const query = graphql`
  query {
    policies: datoCmsStoreOption {
      policy {
        policySectionTitle
        policyDetails {
          description
        }
      }
    }
  }
`
